$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1440px
) !default;

$sm: map-get($container-max-widths, "sm");
$md: map-get($container-max-widths, "md");
$lg: map-get($container-max-widths, "lg");
$xl: map-get($container-max-widths, "xl");

$font-sizes: (
  120px,
  80px,
  50px,
  30px,
  15px,
  18px
);

// TODO mixin with all properties and breakpoints @include font-size($n);
$font-size-1: nth($font-sizes, 1);
$font-size-2: nth($font-sizes, 2);
$font-size-3: nth($font-sizes, 3);
$font-size-4: nth($font-sizes, 4);
$font-size-5: nth($font-sizes, 5);
$font-size-6: nth($font-sizes, 6);

$line-heights: (
  120px,
  80px,
  57px,
  40px,
  33px,
  40px
);

$breakpoints-proportion: (
  0.4,
  0.6, 
  0.7,
  0.8,
  1
);


$lighter-bg: #f4f5f7;
$blue: #3D85EB !default;
$orange: #EB714E !default;
$light-blue: #77cbdc !default;
$main-color: $blue;
$link-color: $orange;
$light-grey: #d9ddde;
$lighter-grey: #F8F8F8;
$grey: #79888c;
$dark-grey: #212020;
$text-color: $grey;
$red: #d3320d;
$brand: $blue;


$colors: (
  "lighter-bg": $lighter-bg,
  "blue": $blue, 
  "orange": $orange,
  "light-blue": $light-blue,
  "light-grey": $light-grey,
  "lighter-grey": $lighter-grey,
  "grey": $grey,
  "dark-grey": $dark-grey,
  "red": $red,
  "yellow": #f8d12f,
  "dark-blue": #030e88,
  "brown": #72492d,
  "orange-card": $orange,
  "dark-pink": #aa336a,
  "pink": #ff799f,
  "purple": #3f2a6f,
  "green": #89ce75
);

@each $name, $color in $colors {
  .bg-#{$name} {
    background-color: $color;
  }
  .text-#{$name} {
    color: $color;
  }
}

$generic-element-heigth: 60px;
$bkg-color-grey: $dark-grey;

$font-size-base: 1rem !default;
$font-size-small: 13px !default;
$bold: 700;
$font-medium-weight: 500;

$body-font-family: 'Lato', sans-serif;
$font-family: $body-font-family;

$main-transition: all .6s cubic-bezier(.165,.84,.44,1);
$effect-line-weight: 2px;
$effect-line-big-width: 75px;
$effect-line-colapsed-width: 8px;
$effect-line-expanded-width: 100%;

// DESKTOP
$nav-height: 90px;
$nav-panel-width: 50vw;
$nav-bar-height: $nav-height;
$nav-bar-breakpoint: 1280px;

$generic-button-margin-bottom: 1rem;
$button-padding-y: .5rem;
$button-padding-x: 6rem;
$button-border-weight: $effect-line-weight;
$button-font-size: 20px;
$input-padding-side: 1rem;

$generic-padding-top: 60px;
$generic-padding-bottom: 100px;
$generic-margin-bottom: 110px;

$fade-in-displacement-height: 15px;

$footer-height: 150px;
$max-fullheight-image-height: 1078px;
$max-85-image-height: 900px;

// TABLETS
$tablet-footer-height: 320px;

// MOBILE

$mobile-generic-padding-side: 1rem;