.user-container {
    height: 80px;
    
    color: $white;
    font-size: $font-size-3;
    
    text-transform: uppercase;
    .box {
        position: relative;
        width: 80px;
        height: 80px;
        background-color: $light-grey;
        color: $grey;
        &.user {
            font-size: $font-size-5 * .8;
            line-height: $font-size-5;
            padding: 7px;
        }   
        &.avatar {
            background-color: $orange;
            color: $white;
            text-align: center;
            line-height: 87px;
        }
        a {
            color: $orange;
            &:hover {
                .icon-close-crossmif::before {
                    color: $blue;    
                }
                color: $blue;
            }
        }
        .logout-container {
            position: absolute;
            bottom: 7px;
            left: 7px;
            .icon-close-crossmif {
                display: inline-block;
                margin-right: 7px;
            }
        }
    }
}