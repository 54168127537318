html {
	overflow-x: hidden;
	// zoom: .5;
	// @include media-breakpoint-up(md) { 
	// 	zoom: .6; 
	// }
	// @include media-breakpoint-up(lg) { 
	// 	zoom: .7; 
	// }
	// @include media-breakpoint-up(xl) { 
	// 	zoom: 1; 
	// }
	 
	
}
body {
	overflow-x: hidden;
	background-color: $lighter-bg;
	color: $text-color;
	font-family: $body-font-family;
	font-weight: 300;
	min-height: 100vh;
}

#main {
	
}

h1, h2, h3, h4, h5, h6 {
	font-weight: $font-weight;
	color: $blue;
	text-transform: uppercase;
}
ul, li {
	list-style: none;
	padding: 0;
	margin: 0;
}

.logo {
	display: block;
	width: 250px;
    height: 80px;
	background-image: url(/frontend/images/mif.svg);
	background-repeat: no-repeat;
	transition: $main-transition;
	text-indent: -9999px;
	@include media-breakpoint-down (sm) {
		width: 100px;
		background-size: 309px;
	}
}

@keyframes shake {
	10%, 90% {
		transform: translate3d(-1px, -50%, 0);
	}

	20%, 80% {
		transform: translate3d(4px, -50%, 0);
	}

	30%, 50%, 70% {
		transform: translate3d(-6px, -50%, 0);
	}

	40%, 60% {
		transform: translate3d(6px, -50%, 0);
	}
}  


.draggable {
    position: absolute;
    cursor: grab;
	transition: box-shadow .2s ease-out;
	img {
		transition: box-shadow .2s ease-out;
	}
}
.z-index-5 {
	box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.04), 0 9px 46px 8px rgba(0, 0, 0, 0.04), 0 11px 15px -7px rgba(0, 0, 0, 0.04);
}

.big-btn {
	transition: all .2s ease-out;
	width: $generic-element-heigth;
	height: $generic-element-heigth;
	display: block;
	text-align: center;
	line-height: $generic-element-heigth + $generic-element-heigth / 10;
	background-color: $blue;
	float: right;
	font-size: $font-size-4;
	font-weight: $font-weight;
	text-transform: uppercase;
	color: $white;
	border: 0;
	.material-icons {
		font-size: $font-size-3;
		@include center(xy);
	}
	span:before{
		color: $white;
	}
	&.round {
		border-radius: 50%;
	}
	&:hover {
		background-color: $light-blue;
		color: $white;
		// span:before{
		// 	color: $blue;
		// }
	}
	&.orange-mif {
		background-color: $orange;
		color: $white;
		&:hover {
			color: $white;
			background-color: $light-blue;
		}
	}
	&.light-blue-mif {
		background-color: $light-blue;
		color: $white;
		&:hover {
			color: $white;
			background-color: $blue;
			span:before{
				color: $white;
			}
		}
	}
	&.double {
		min-width: $generic-element-heigth * 2;
	}
	
}
.icon-orange {
	&:before{
		color: $orange;
	}
}
.icon-light-blue {
	&:before{
		color: $light-blue;
	}
}
.icon-blue {
	&:before{
		color: $blue;
	}
}


input{
	&:focus { 
		outline: none !important;
	}
	font-weight: $font-weight;
	height: $generic-element-heigth;
	width: 100%;
	border: 0;
	color: $white;
	font-size: $font-size-4;
	background-color: $blue;
	border-radius: 0;
	&::placeholder {
		color: $light-blue;
	}
	padding-left: $input-padding-side;
	padding-right: $input-padding-side;
}
a.submit, button[type=submit] {
	height: $generic-element-heigth;
	width: $generic-element-heigth;
	background-color: $blue;
	color: $white;
	float: right;
	border: 0;
	display: block;
	font-size: $font-size-4;
	line-height: $generic-element-heigth;
	text-align: center !important;
	transition: $main-transition;
	&:hover {
		background-color: $light-blue;
	}
}
button[type=submit].double {
	font-size: $font-size-5;

}
textarea{
	&:focus { 
		outline: none !important;
	}
}

a {
    text-decoration: none;
    position: relative;
	transition: $main-transition;
    color: $link-color;
    &[data-label] {
        display: inline-block;
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            content: attr(data-label);
            text-decoration: underline;
            text-decoration-color: $brand;
            color: rgba(255, 255, 255, 0);

            opacity: 0;
            transform: translateY(5px);

            transition-property: opacity, transform;
            transition-duration: 0.15s;
            transition-timing-function: ease-out;
        }

        &:hover {
            text-decoration: none;
            color: $light-blue !important;
            &:after {
                top: 0;
                opacity: 1;
                transform: translateY(0px);
            }
        }
    }
    &:hover {
        text-decoration: none;
    }
}

.px-0, .pr-0 {
	@include media-breakpoint-down (md) {
		padding-left: $mobile-generic-padding-side !important;
		padding-right: $mobile-generic-padding-side !important;
	}
}

.px-sm-0 {
	@include media-breakpoint-down (sm) {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

form {
	padding: 0;
}

.status-message {
	font-size: $font-size-small;
	padding: $font-size-small / 2;
	&.error {
		color: $orange;
	}
}