.login {
    // @include center(xy);
    
    #form-register-password {
        display: none;
    }
    #form-recover-password {
        display: none;
    }
    input{
       
        &::placeholder {
            color: rgba(255, 255, 255, .3);
        }
    }
}