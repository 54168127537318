.main-color-bg {
	background-color: $main-color;
}

.grey-color-bg {
	background-color: $bkg-color-grey;
}

.dark-grey-color-bg {
	@include bg-dark-grey();
}

.main-color-text {
	color: $main-color !important;
	a {
		text-decoration: underline;

		color: $main-color;
		&:hover {
			text-decoration: none;
		}
	}
	&.bold {
		font-weight: bold;

		text-transform: uppercase;
	}
	p {
		color: $main-color !important;
	}
}

.white-color-bg {
	background-color: #fff;
}

.white-color-text {
	color: #fff;
}

.letter-space-1 {
	letter-spacing: 1px;
}

.letter-space-2 {
	letter-spacing: 2px;
}

.letter-space-3 {
	letter-spacing: 3px;
}

.letter-space-4 {
	letter-spacing: 4px;
}

.font-300 {
	font-weight: 300;
}

.font-400 {
	font-weight: normal;
}

.font-500 {
	font-weight: 500;
	
}
.font-600 {
	font-weight: 600;
}
.font-700,
.font-bold {
	font-weight: bold;
}

.font-900 {
	font-weight: 900;
}

.text-uppercase {
	text-transform: uppercase;
}

.inline-d {
	display: inline-block;
}

.z1 {
	z-index: 1;
}
.z2 {
	z-index: 2;
}
.z3 {
	z-index: 3;
}
.z4 {
	z-index: 4;
}
.z5 {
	z-index: 5;
}

.mono {
    font-family: 'Roboto Mono', monospace;
 }

 .grey {
     color: $grey;
 }
 .bg-grey {
     background-color: $grey;
 }
 

.component {
	@include add-generic-paddings();
	@include add-generic-margins();
	box-sizing: border-box;
	transition: $main-transition;
	&:last-of-type {
		margin-bottom: 0;
	}
	h1, h2, h3, h4, h5, h6 {
		opacity: 0;
		transform: translateX(-$fade-in-displacement-height);
		transition: $main-transition;
		transition-delay: .1s;
	}
	
	&.menu-open-hide {
		transform: translateX(100px);
		opacity: .9;
	}
	button:last-of-type {
		margin-left: 1rem;
		@include media-breakpoint-down(sm) {
			margin-left: 0;
		}
	}
	button:only-of-type {
		margin-left: 0;
	}
	li {
		list-style: disc;
		&::marker {
			color: $link-color;
			
		}
	}
	@include media-breakpoint-down(sm) {
        .pt-80, .pt-100 {
			padding-top: 0 !important;
		}
    }
	.is-transparent {
		background-size: contain !important;
		background-repeat: no-repeat !important;
	}
	
}
* {
	&.fade-in {
		transition: $main-transition;
		opacity: 1 !important;
		transform-origin: translateX(-20px)  !important;
		transform: translateX(0) !important;
	}
	&.fade-out {
		transform: translateX(-$fade-in-displacement-height) !important;
		transition: $main-transition;
		opacity: 0 !important;
	}
	&.remove-fade-right {
		transition: $main-transition;
		opacity: 0 !important;
		transform: translateX(40px) !important;
	}
}

.line {
	@include add-line();
}

.profile {
	position: relative;
	width: 160px;
	height: 160px;
	img {
		left: 0;
		border-radius: 50%;
		overflow: hidden;
		max-width: 100%;
		max-height: 1100%;
		display: inline-block;
		position: absolute;
		z-index: 2;
		// @include center(x);
	}
}

.load-more-arrows {
	display: inline-block;
	width: 80px;
	height: 80px;
	position: relative;
	padding: 0;
	border: 0;
	&:after {
		content: "";
		opacity: 1;
		width: 90%;
		height: 90%;
		top: 4px;
		left: 4px;
		background-image: url("/frontend/images/load-more-arrows.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		// transform-origin: center center;
		transform: rotate(360deg);
		transition: $main-transition;
		position: absolute;
	}
	.dark-grey-color-bg & {
		&:after {
			background-image: url("/frontend/images/load-more-arrows-yellow.svg");
		}
	}
	span {
		display: none;
	}
	&:hover {
		&:after {
			transform: rotate(180deg);
		}
	}
	&.loaded {
		&:after {
			transform: rotate(-260deg);
		}
	}
}
.close-button {
    $size: 40px;
    height: $size;
    width: $size;
    position: relative;
    box-sizing: border-box;
    line-height: $size;
    display: inline-block;

    &:before, &:after {
        $width: $size;
        $height: 7px;
        transform: rotate(-45deg);
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -$height/2;
        margin-left: -$width/2;
        display: block;
        height: $height;
        width: $width;
        background-color: $yellow;
        transition: all 0.25s ease-out;
    }

    &:after {
        transform: rotate(-135deg);
    }

    &:hover {

        &:before, &:after {
            transform: rotate(0deg);
        }
    }
}

.list-container {
	width: 100%;

	.kewywordset & {
		> ul:not(.use-cases-list) {
			transition: all .6s cubic-bezier(.165,.84,.44,1);
			max-width: 60%;
			width: 100%;
			height: auto;

			
			&:hover > *,
			&:focus-within > * {
				a {
					opacity: 0.23;
					transition: all .2s ease-out;
				}
			
			}
			&:hover > :hover,
			&:focus-within > :focus {
				a {
					transform: scale(1.05) translateX(10px);
					opacity: 1;
					// color: $yellow !important;
				}
			}
			
			li {
				opacity: 0;
				// background-color: red;
				transition: $main-transition;
				font-weight: 100;
				height: 5rem;
				margin-bottom: 0;
				border-bottom: solid .5px $light-grey;
				vertical-align: middle;
				position: relative;
				overflow: hidden;
				&:last-child {
					border-bottom: none;
				}
				&::before {
					content: '';
					display: inline-block;
					height: 100%;
					vertical-align: middle;
				}
				> a {
					transition: $main-transition;
					color: $dark-grey;
					// display: block;
					display: inline-block;
					vertical-align: middle;
					// @include center(y);
					&:after {
						display: none;
					}
					&:hover {
						color: $white;
					}
					&.active {
						transform: scale(1.05) translateX(10px);
						opacity: 1;
						color: $yellow !important;
					}
				}
				&.opened {
					height: 200px;
					a {
						vertical-align: top;
					}
				}
				&.in {
					opacity: 1;
				}
			}
			@include media-breakpoint-down(xl) {
				max-width: 90%;
			}
		}
	}
}

.keywordset-nav {
        
	width: 100vw;
	text-align: center;
	z-index: 1;
	transition: none;
	padding-top: 1rem;
	ul {
		display: inline-block;
		li {
			display: inline-block;
			margin-right: 40px;
			&:last-child {
				margin-right: none;
			}
			a {
				color: $yellow !important;
				font-size: $font-size-small;
				&.active {
					&:after {
						opacity: 1;
						transform: translateY(2px);
					}
					&:hover {
						&:after {
							transform: translateY(0px);
						}
					}
				}
			}
		}
	}
	@include bigger-than(1440px) {
		position: absolute;
		transform-origin: left top;
		transform:rotate(-90deg) translateX(-100%);
		width:100vh;
		padding-top: 0;
		left: 70px;
		ul {
			display: block;
			float: right;
			margin-right: 100px;
		}
	}
	@include media-breakpoint-down(xl) { 
		text-align: unset;
		padding-left: 100px;
	}
	@include media-breakpoint-down(md) {
		display: none;
	}
}

.bold {
	font-weight: $bold !important;
}

.small {
	font-size: $font-size-small;
	line-height: $font-size-small * 1.5;
}

.animate-in {
	p, a.btn {
		opacity: 0;
	}
}