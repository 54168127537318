$card-width: 350px;
$card-height: 500px;

$card-input-size: 70px;
.app-instructions {
    @include smaller-than(990px) {
        display: none;
    }
}
.app-warning {
    display: none;
    @include center(xy);
    @include smaller-than(990px) {
        display: block;
    }
    .material-icons {
        font-size: $font-size-1;
        display: block; 
    }
}
.homepage {
    // background-color: $white;
    .material-icons {
        font-size: $font-size-6;
        color: $blue;
    }
    // background-color: $light-grey;
    // width: $card-width * 1.08;
    height: $card-height * 1.05;
    // @include center(x);
    margin: auto;
    position: relative;
    @include smaller-than(990px) {
        display: none;
    }
}
.card-picture-container {
    border: dashed $light-blue $effect-line-weight;
    background-color: $white;
    width: $card-width + $card-input-size / 2;
    height: $card-height + $card-input-size / 2;
    padding: $card-input-size / 2;
    position: relative;
    box-sizing: border-box;
    margin-right: $card-input-size / 2;
}
 .card-holder {
    position: relative;
    z-index: 100;
    background-color: $white;
    width: $card-width;
    height: $card-height;
    margin-right: $card-input-size / 2;
    float: left;
    @include center(xy);
    // top: 20px;
    border-radius: $border-radius;
    // box-shadow: 0 14px 18px 3px rgba(0, 0, 0, 0.04), 0 9px 26px 8px rgba(0, 0, 0, 0.04), 0 11px 15px -7px rgba(0, 0, 0, 0.04);
    border-style: solid;
    border-color: $lighter-grey;
    border-width: $effect-line-weight;
    .tooltip {
        visibility: hidden !important;
    }
    .mif-symbol {
        width: 53px;
        height: 50px;
        background-image: url(/frontend/images/mif-symbol.svg);
        background-repeat: no-repeat;
        position: absolute;
        bottom: -10px;
        right: -10px;
        background-size: 60%;
    }
}
$element-box-height: $card-height;
$element-box-width: 245px;
$element-margin: 20px;
.elements-container {
    float: left;
    width: $card-width + $card-input-size * 1.6;
    border: dashed $light-grey $effect-line-weight;
    border-radius: $border-radius;
    position: relative;
    background-color: $lighter-grey;
    height: $card-height + $card-input-size / 2;
    padding: $card-input-size / 5;
    .elements-tabs-titles {
        position: absolute;
        top: -$card-input-size / 2;
        width: 100%;
        left: 0;
        flex-grow: 1;
        .elements-title
        {
            font-size: $font-size-small;
            background-color: $blue;
            height: $generic-element-heigth / 2;
            line-height: $generic-element-heigth / 2;
            text-align: center;
            color: $white;
            text-transform: uppercase;
            padding-left: $generic-element-heigth / 7;
            padding-right: $generic-element-heigth / 7;
            // display: block;
            // margin-right: 1px;
            transition: $main-transition;
            &:hover, &.active {
                background-color: $light-blue;
            }
            // float: left;
            
            
            // position: absolute;
            
            // top: -$element-margin * 4;
            // left: -(($element-margin * 2 ) + $element-box-width * 1.5);
            // &:nth-of-type(2) {
            //     top: (($element-margin * 1.4) + $element-box-height);
            // }
            // &:nth-of-type(3) {
            //     top: -$element-margin / 2;
            //     left: auto;
            //     right: -(($element-margin * 2) + $element-box-width);
            // }
            // &:nth-of-type(4) {
            //     top: (($element-margin * 1.4) + $element-box-height);
            //     left: auto;
            //     right: -(($element-margin * 2) + $element-box-width);
            // }
        }
    }
    
    .elements {
        // display: none;
        visibility: hidden;
        // &:first-child {
        //     display: block;
        // }
        // @include center(xy);
        background-color: red;
        // overflow-y: scroll;
        // overflow-x: hidden;
       
        // top: $element-margin;

        // padding-top: $element-margin * 1.5;
        // padding-left: $element-margin;
        // padding-right: $element-margin;

        width: $element-box-width * 1.5;
        // height: $card-height + $card-input-size / 2;
        // position: absolute;
        //left: -(($element-margin * 2 ) + $element-box-width * 1.5);
        
        
        text-align: center;
        color: $grey;
        
        p.title {
            text-transform: uppercase;
            position: absolute;
            width: 100%;
            top: -($element-margin * 1.5 );
        }
        // &:nth-of-type(2) {
        //     top: (($element-margin * 1.5) + $element-box-height * 1.1);
        //     height: $element-box-height;
        // }

        // &:nth-of-type(3) {
        //     top: $element-margin;
        //     left: auto;
        //     right: -(($element-margin * 2) + $element-box-width * 1.5);
        // }
        // &:nth-of-type(4) {
        //     top: (($element-margin * 1.5) + $element-box-height * 1.1);
        //     left: auto;
        //     right: -(($element-margin * 2) + $element-box-width * 1.5);
        // }
        // .elements-scroll {
        //     padding-top: $element-margin;
        //     overflow-y: scroll;
        //     overflow-x: hidden;
        //     position: relative;
        //     width: 100%;
        //     height: $element-box-height - $element-margin * 1.5;
        // }
    }
    .card-input {
        span[class^="icon-"], .duplicate-btn, .resize-handle {
            display: none;
        }
        
    }

}
.card-input {
    width: $card-input-size;
    height: $card-input-size;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    
    .resize-handle {
        transition: opacity .1s ease-out;
        position: absolute;
        width: 0px;
        height: 0px;
        margin-top: -15px;
        margin-left: -15px;
        border-style: solid;
        border-width: 0 0 16px 16px;
        border-color: transparent transparent $light-blue transparent;
        cursor: nw-resize !important;
        opacity: 0;
    }
    .duplicate-btn {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0;
        transition: opacity .2s ease-out;
        // display: none;
        cursor: pointer !important;

    }
    &:hover, &:focus {
        .resize-handle {
            opacity: 1;
        }
        .duplicate-btn {
            opacity: 1;
        }
    }
    span[class^="icon-"] {
        display: block;
        top: 0;
        left: 0;
        position: absolute;
        font-size: $font-size-small;
        &:before {
            color: $orange;
        }
        opacity: 0;
        transition: opacity .2s ease-out;
        cursor: pointer !important;
        &:hover {
            opacity: 1 !important;
        }
    }
    &:hover {
        span[class^="icon-"] {
            opacity: .5;
        }
    }
    &.letter {
        font-weight: $bold;
        font-size: $font-size-1 * 2;
        color: $light-blue;
        text-rendering: optimizeLegibility;
        font-family: Arial, Helvetica, sans-serif;
        display: table;
        // min-height: 80px;
        // min-width: 80px;
        height: auto;
        width: auto;
        line-height: $font-size-1 * 1.5;
        position: absolute;
        
       
        span[class^="icon-"] {
            top: -25px;
            left: -15px;
            position: absolute;
            font-size: $font-size-small;
            &:before {
                color: $orange;
            }
            opacity: 0;
            transition: opacity .2s ease-out;
            cursor: pointer !important;
            &:hover {
                opacity: 1 !important;
            }
        }
        span.icon-plusmif {
            left: auto;
            right: 30px;
            &:before {
                color: $blue;
            }
        }
        span.icon-minusmif {
            left: auto;
            right: 0px;
            &:before {
                color: $light-blue;
            }
        }
        &:hover {
            span[class^="icon-"] {
                opacity: .5;
            }
        }
        text {
            display: table-row;
            white-space: nowrap;
            transform-origin: bottom right;
        }
    }
}

.tooltip {
    $self: &;
    position: absolute;
    padding: $button-padding-y;
    background-color: $light-blue;
    min-width: 120px;
    min-height: 40px;
    top: -20px;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    text-align: center;
    color: $white;
    white-space: nowrap;
    text-overflow: ellipsis;
    @include center(x);
    p {
        margin: 0;
        height: 100%;
        width: 100%;
        white-space: nowrap;
        font-size: $font-size-small;
        line-height: $font-size-small;
        text-overflow: ellipsis;
        // @include center(xy);
        position: relative;
    }
}