.main-functions {
   z-index: 100;
   position: fixed;
   bottom: 0;
   left: 0;
   height: $generic-element-heigth;
   width: 100vw;
   @include smaller-than(990px) {
      display: none;
  }
}