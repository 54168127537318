$block-size: $generic-element-heigth;
.tools {
    
    @include center (x);
    position: fixed;
    bottom: -$generic-element-heigth;
    background-color: $blue;
    height: $block-size;
    width: 50vw;
    z-index: 100000;
    
    .big-btn {
        float: left;
        &.drag-btn {
            position: absolute;
            @include center(x);
            top: -$block-size;
            cursor: grab;
        }
    }
    .add-text-input {
        background-color: $light-blue;
        color: $light-grey;
        font-size: $font-size-3;
        line-height: $font-size-3;
        position: absolute;
        width: $block-size * 2;
        height: $block-size;
        top: 0px;
        z-index: -2;
        left: 0;
        border: 0;
    }
    .big-btn.shortcut {
        position: absolute;
        top: 0;
        z-index: -2;
        left: $block-size * 2;
    }
    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: $block-size;
        background-color: $blue;
        top: 0;
        left: 0;

    }
    .colors-container {
        width: ($block-size / 3) * 4;
        height: $block-size;
        background-color: $light-grey;
        float: left;
        position: relative;
        .color-btn {
            width: $block-size / 3;
            height: $block-size / 3;
            // background-color: $light-blue;
            float: left;
            cursor: pointer;
        }
    }
    @include smaller-than(990px) {
        display: none;
    }
}