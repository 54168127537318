.main-nav {
    // overflow: hidden;
    transition: $main-transition;
    position: fixed;
    left: 0;
    top: 0;
    width: 70px;
    min-height: 100vh;
    z-index: 200;
    @include media-breakpoint-down(sm) {
        min-height: auto;
    }
    // &:after {
        
    //     position: fixed;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 90px;
    //     background-color: $lighter-grey;
    //     z-index: -1;
    //     transition: $main-transition;
    //     @include smaller-than($nav-bar-breakpoint) {
    //         content: "";
    //     }
    //     @include smaller-than(1200px) {
    //         content: "";
    //     }
    // }
    .close-button {
        position: absolute;
        right: 2.5rem;
        top: 2rem;
        z-index: 1;
        &:after, &:before {
            background: $white;
        }
        @include media-breakpoint-down(sm) {
            right: 1.5rem;
            top: 1rem;
        }
    }
    .panel {
        position: absolute;
        top: 0;
        left: 0;
        width: $nav-panel-width;
        height: 100vh;
        background-color: $blue;
        transform: translateX(-100%);
        transition: $main-transition;
        z-index: 3;
        @include media-breakpoint-down(md) {
            width: 100vw;
        }
    }
    
    .burguer-container {
        background-color: transparent;
        height: 40px;
        width: 40px;
        padding: 0;
        border: none;
        @include center(x);
        bottom: 20px;
        position: fixed;
        right: 0rem;
        
        z-index: 3;
        @include smaller-than($nav-bar-breakpoint) {
            // top: ($nav-bar-height / 2) - (40px / 2);
        }
        @include media-breakpoint-down(sm) {
            right: .8rem;
        }
        .burguer {
            background-color: $blue;
            @include burger(40px, 7px, 5px, $blue);
            &:after {
                background-color: $blue;
            }
            &:before {
                background-color: $blue;
            }
        }
        &:hover {
            .burguer { 
                &:after {
                    transform: translateY(2px);
                }
                &:before {
                    transform: translateY(-2px);
                }
            }
        }
        @include larger-than(990px) {
            bottom: auto;
            top: 1rem;
        }
    }
    .main-nav-list {
        @include center(y);
        left: 70px;
        // margin-top: -10%;
        width: 90%;
        .first-level-nav {
            
            
            &:hover > *,
            &:focus-within > * {
                a {
                    opacity: 0.23;
                    transition: all .2s ease-out;
                }
            
            }
            &:hover > :hover,
            &:focus-within > :focus {
                a {
                    transform: scale(1.05) translateX(3px);
                    opacity: 1;
                }
            }
            li {
                transition: $main-transition;
                font-weight: 100;
                margin-bottom: 1.5rem;
                vertical-align: middle;
                position: relative;
                
                &:last-child {
                    border-bottom: none;
                }
                &::before {
                    content: '';
                    display: inline-block;
                    height: 100%;
                    vertical-align: middle;
                }
                > a {
                    text-transform: uppercase;
                    transition: $main-transition;
                    color: $white;
                    display: inline-block;
                    font-weight: $font-medium-weight;
                    vertical-align: middle;
                    font-size: $font-size-4;
                    // opacity: 0.6;
                    &:after {
                        display: none;
                    }
                    &:hover {
                        // color: $white;
                        color: $white;
                    }
                    
                }
                &.active {
                    > a {
                        // transform: scale(1.05) translateX(3px);
                        opacity: 1;
                        color: $light-blue;
                        
                    }
                    
                }
                &.highlight {
                    margin-top: 90px;
                    a {
                        font-weight: $font-medium-weight;
                        &:hover {
                            transform: scale(1.05) translateX(6px);
                        }
                    }
                }
            }
            @include media-breakpoint-down(sm) {
                width: 70%;
            }
        }
        .social-network-icons {
            margin-top: 100px;
            // float: left;
            li {
                a {
                    color: $white;
                    
                }
            }
        }
        .second-level-nav {
            // float: left;
            // margin-left: 50px;
            margin-top: 100px;
            display: none;
            @include smaller-than($nav-bar-breakpoint) { 
                display: block;
            }
            li {
                display: inline-block;
                margin-right: 40px;
                line-height: $font-size-6 * 1.5;
                a {
                    transition: $main-transition;
                    color: $grey;
                    text-transform: uppercase;
                    font-size: $font-size-small * .95;
                    line-height: $font-size-6 * 1.5;
                    &:hover {
                        color: $dark-grey;
                    }
                }
            }
        }
        @include media-breakpoint-down(sm) {
            left: 20px;
            .first-level-nav {
                
                li {
                    margin-bottom: .6rem;
                    a {
                        font-size: $font-size-4 / 1.2;
                    }
                    &.highlight {
                        margin-top: 30px;
                    }
                }
            }
            .social-network-icons {
                margin-top: 40px;
                float: none;
            }
            .second-level-nav { 
                float: none;
                margin-top: 30px;
                margin-left: 0;
            }
        }
        @include smaller-height-than(900px) {
            
            .first-level-nav {
                
                li {
                    margin-bottom: .6rem;
                    a {
                        font-size: $font-size-4 / 1.2;
                    }
                    &.highlight {
                        margin-top: 30px;
                    }
                }
            }
            .social-network-icons {
                margin-top: 40px;
                float: none;
            }
            .second-level-nav { 
                float: none;
                margin-top: 30px;
                margin-left: 0;
            }
        }
    }
    .keywordset-nav {
       left: 30px;
       z-index: 2;
       li {
           a {
               color: $text-color !important;
           }
       }
       .secundary-nav {
            float: left;
            margin-right: 0;
        }
    }   
   
    &.active {
        .burguer-container {
            .burguer {
                transition: $main-transition;
                @include burger-to-cross();
                opacity: 0;
            }
        }
        .panel {
            transform: translateX(0);
        }
        
    }
    
    

   
    
   
    
    .logo {
        position: fixed;
        right: 2rem;
        top: 2.9rem;
        background-color: $text-color;
        @include media-breakpoint-down(sm) {
            top: 2.3rem;
            right: 1rem;
        }
        &:hover {
            background-color: $link-color;
        }
        &.yellow {
            background-color: $yellow;     
        }
        &.light {
            background-color: $white;       
        }
    }
    
    @include smaller-than($xl - 1px) { 
        .keywordset-nav {
            padding-top: 30px;
            .secundary-nav { 
                float: none;
            }
        }
        .main-nav-list ul {
            margin-top: 0;
        }
    }
    @include smaller-than($nav-bar-breakpoint) { 
        .keywordset-nav {
            padding-top: 25px;
            .secundary-nav {
                display: none;
            }
        }
        .logo {
            top: 2rem;
        }
        
    }
    @include media-breakpoint-down(sm) {
        .logo {
            top: 2.3rem;
            right: 1rem;
        }
        
    }
    
    .dark-grey-color-bg & {
        li {
            a {
                color: $text-color !important;
            }
        }
    }
}
