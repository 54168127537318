// @for $i from 1 through 6 {
//     h#{$i} {
//         font-weight: $bold;
//     }
// }

@mixin bg-dark-grey {
    background-color: $dark-grey;
    color: $white;
    // border-color: $dark-grey;
    * {
        transition: $main-transition;
        color: $white;
        // border-color: $dark-grey;
    }
    a {
        color: $white !important;
    }
}
@mixin add-generic-paddings() {
    padding-top: $generic-padding-top;
    padding-bottom: $generic-padding-bottom;
    
    @include media-breakpoint-down(sm) {
        padding-top: $generic-padding-top;
        padding-bottom: $generic-padding-bottom / 2;
    }
}
@mixin add-generic-margins() {
    margin-bottom: $generic-margin-bottom;
    
    @include media-breakpoint-down(sm) {
        
        margin-bottom: 0;
    }
}

@mixin add-line ($color: $link-color, $top: 80%, $width: null, $leftIn: null) {
    & {
        position: relative;
    }
    &:after {
        transition: $main-transition;
        content: "";
        position: absolute;
        background-color: $color;
        @if $leftIn  { 
            left: $leftIn;
        }  @else {
            left: -($effect-line-big-width / 1.5);
        }
        
        @if $top  {
            top: $top;
            bottom: auto;
        } @else {
            // top: auto;
            @include center(y);
        }
        
        width: 0;
        height: $effect-line-weight;
    }
    
    &.in {
        &:after {
            width: $effect-line-big-width / 2;
            @if $width  {
              width: $width;
            }
        }
    }
    &.out {
        &:after {
            width: 0;
        }
    }
    &.top {
		&:after {
            left: 0 !important;
			transform: none;
		}
	}
}